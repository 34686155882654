import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "./DialogTitle";
import ExitConfirmation from "./ExitConfirmation";
import Button from "./Button";
import { LogoType } from "../types";

type DialogProps = {
  open: boolean;
  title: React.ReactNode | string;
  logo?: LogoType;
  menuTitle?: React.ReactNode | string;
  onClose: Function;
  onAction?: any;
  children: React.ReactNode;
  classes: any;
  actionLabel: string;
  disabled: boolean;
  confirmBeforeExit: boolean;
  submitting: boolean;
};

const useLogoStyles = makeStyles(() => ({
  img: {
    width: 75,
    height: 75,
    marginBottom: 5,
    objectFit: "scale-down",
  },
}));

const Logo = ({ logo }: { logo: LogoType }) => {
  const classes = useLogoStyles();
  return (
    <Typography align="center">
      <img
        src={
          typeof logo === "string" ? `data:image/png;base64,${logo}` : logo.src
        }
        className={classes.img}
        alt=""
      />
    </Typography>
  );
};

const Dialog = ({
  title,
  logo,
  menuTitle = "",
  onClose,
  onAction,
  children,
  classes,
  actionLabel,
  disabled,
  confirmBeforeExit,
  submitting,
}: DialogProps) => {
  const { t } = useTranslation();
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const handleCloseWithConfirmation = () => {
    setShowConfirmExit(true);
  };
  const yesConfirmBeforeExit = () => {
    onClose();
  };
  const noConfirmBeforeExit = () => {
    setShowConfirmExit(false);
  };

  const handleClose = showConfirmExit
    ? null
    : confirmBeforeExit
    ? handleCloseWithConfirmation
    : onClose;
  const dialogTitle = showConfirmExit ? t("exit") : menuTitle;

  return (
    <Fragment>
      <DialogTitle disabled={submitting} onClose={handleClose}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <>
          <Paper className={classes.titleContainer} elevation={0}>
            <Typography className={classes.title} align="center" variant="h5">
              {title}
            </Typography>
            {logo && <Logo logo={logo} />}
          </Paper>
          {showConfirmExit ? (
            <ExitConfirmation
              onYes={yesConfirmBeforeExit}
              onNo={noConfirmBeforeExit}
            />
          ) : (
            children
          )}
        </>
      </DialogContent>
      {!showConfirmExit && (
        <DialogActions className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            onClick={onAction}
            size="large"
            disabled={disabled || !onAction}
            fullWidth
            aria-label={actionLabel}
            className={classes.button}
            submitting={submitting}
          >
            {actionLabel}
          </Button>
        </DialogActions>
      )}
    </Fragment>
  );
};

export default Dialog;
