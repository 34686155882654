import { put, takeEvery, call, select } from "redux-saga/effects";
import { PayloadAction } from "typesafe-actions/dist/type-helpers";
import qs from "qs";

import { TrustlyInfo, TrustlyLog } from "types";

import { selectIAVToken, selectProviderInfo } from "../selectors";
import { setInstitution } from "../actions/institution";
import { addSelectionAccounts } from "../actions/selectionAccounts";
import { RECEIVED_TRANSACTION, LOG_TRUSTLY_EVENT } from "../actions/trustly";

import { getIAVServer, getSdk } from "./utils";
import uxTransactionSaga from "./uxTransactionSaga";

const trustlyAccountsUri = "/auth/trustly/accounts";

type PayloadType = {
  transaction: string;
  type: string;
};

const receiveTransactionSaga = uxTransactionSaga(function* ({
  payload: { transaction, type },
}: PayloadAction<string, PayloadType>) {
  const iavServer = yield getIAVServer();
  const iavToken = yield select(selectIAVToken);
  const providerInfo: TrustlyInfo = yield select(selectProviderInfo);

  const { accounts, providerId, providerName, microDeposit } = yield call(
    iavServer.post,
    trustlyAccountsUri,
    {
      transaction,
      type,
    },
    iavToken
  );

  if (!microDeposit) {
    yield put(
      setInstitution({
        id: providerId,
        name: providerName,
        logo: {
          src: `${providerInfo.endpoint}/assets/institutions/${providerId}.png`,
        },
      })
    );

    yield put(addSelectionAccounts(accounts));
  }
  return { accounts, microDeposit };
});

function* logTrustlyEventSaga({ payload }: PayloadAction<string, TrustlyLog>) {
  try {
    const sdk = yield getSdk();
    const {
      transaction,
      requestType,
      startAt,
      status,
      error,
      externalDdaId,
      userId,
      fspId,
      payerId,
      institution,
      transactionId,
    } = payload;
    const fsp = sdk.fsps(fspId);
    const transactionObj = transaction ? qs.parse(transaction) : null;

    const iavRequestData = {
      externalDdaId,
      userId,
      requestType,
      startAt,
      requestId: transactionId ?? transactionObj?.transactionId,
      institutionName: institution?.name,
      institutionId: institution?.id,
      iavStatus: status,
      errorMessage: error,
      accountVerificationStatus: transaction,
    };
    yield call(fsp.payers(payerId).iavRequests.create, iavRequestData, {
      userId,
    });
  } catch (exception) {
    // ignore error
  }
}

function* trustlySaga() {
  yield takeEvery(RECEIVED_TRANSACTION, receiveTransactionSaga);
  yield takeEvery(LOG_TRUSTLY_EVENT, logTrustlyEventSaga);
}

export default trustlySaga;
