/* eslint no-template-curly-in-string: 0 */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const defaultTranslations = {
  en: {
    default: {
      error: {
        title: "Error",
        ELIGIBLE_ACCOUNTS_ALREADY_IN_PROFILE:
          " The eligible accounts returned are already in your profile.",
        NO_ELIGIBLE_ACCOUNTS:
          "We are unable to find any checking or saving accounts.",

        PLAID_AUTH_ERROR: "We are unable to retrieve your account(s).",
        PLAID_ACCT_ERROR: "We are unable to retrieve your account(s).",
        unableToLoadScript: "Unable to load script.",
        MICRO_DEPOSIT_NOT_SUPPORTED: "Micro deposit not supported",
        UNABLE_TO_ADD_EXTERNAL_DDA: "We are unable to process your account(s).",
        UNABLE_TO_ADD_EXTERNAL_DDA_TO_VERIFY:
          "We are unable to process your account(s).",
        UNABLE_TO_UPDATE_EXTERNAL_DDA:
          "We are unable to process your account(s).",
        UNABLE_TO_ADD_PENDING_MICRO_DEPOSIT_DDA:
          "We are unable to process your account(s).",
        UNABLE_TO_RETRIEVE_EXTERNAL_DDAS:
          "We are unable to process your account(s).",
        API_1162:
          "Your role is not authorized to perform this function, please contact your system administrator.",
        NO_ROUTING_NUMBER:
          "We are unable to find your institution's routing number, please try again later.",
        DATA_NOT_READY:
          "Unable to complete setup of your external account at this time.  Please try again later.",
        RETRIEVE_DATA_FAILED: "We are unable to process your account(s).",
      },
      iav: {
        selectAccountTitle: "Select your account",
        selectAccountsTitle:
          "Link the eligible accounts below to enable transfers to those accounts.",
        subtitle: "{{fspName}} doesn't save your bank login.",
        selectAccountsToLink: "Eligible Accounts to Link",
        linkUnlink: "Link",
        mcdInstruction1:
          "The next step will ask you to enter a <strong>reference code</strong>.",
        mcdInstruction2:
          "This <strong>reference code</strong> can be found on your online banking transaction.  It is the first 4 characters and should look like this example:",
        mcdExampleCodeLabel: "Example Code",
        mcdExampleCode: "<strong>P5S5</strong> 220216",
        mcdInstruction3:
          "If this were the provided code, you would enter only '<strong>P5S5</strong>'",
      },
      exitConfirmationMessage: "Are you sure you want to exit?",
      exit: "Exit",
      button: {
        add: "Add",
        cancel: "Cancel",
        next: "Next",
        done: "Done",
        yesExit: "Yes, exit",
        noContinue: "No, continue",
      },
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: defaultTranslations,
    fallbackNS: "default",
    fallbackLng: {
      default: ["en"],
    },
  });

export default i18n;
